import React, { createContext } from "react";
import { useExpanded, useFilters, useTable } from "react-table";
import { TableBody } from "./TableBody";
import { TableHead } from "./TableHead";

export const TableContext = createContext({});

export const Table = ({
  columns,
  data,
  id,
  nestedTable,
  status = "",
  ...props
}) => {
  const initialState = {
    hiddenColumns: props.careType !== "hospitalization" ? ["days"] : [],
  };
  const tableInstance = useTable(
    { columns, data, initialState },
    useFilters,
    useExpanded
  );
  const { getTableProps } = tableInstance;

  return (
    <TableContext.Provider value={{ ...tableInstance }}>
      <table className="myTable" {...getTableProps()} {...props}>
        <TableHead />
        <TableBody
          id={id}
          status={status}
          getPatientData={props.getPatientData}
        />
      </table>
    </TableContext.Provider>
  );
};
