import { useEffect, useMemo, useState } from "react";
import { patientService } from "../../services/patient.services";
import { helpFunc } from "../../helpers/helpFunction";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { validation } from "../../helpers/validation_schemes/patientValidation";

export const AnamnezaLogic = (patient, getPatientCallback) => {
  const [weightHistory, setWeightHistory] = useState([]);

  useEffect(() => {
    getWeightHistory();
    setFormValues(patient);
  }, []);

  const getWeightHistory = async () => {
    const response = await patientService.getWeightHistory(patient.id);
    if (response.status === 200) {
      setWeightHistory(
        response.data.map((item) => ({
          ...item,
          date: helpFunc.formatDateWithTime(item.history_datetime),
        }))
      );
    }
  };

  const handleSavePatient = async (data) => {
    const response = await patientService.patchPatient(patient.id, {
      ...data,
      body_constitution: data.body_constitution.value || null,
      allergy_drugs: data.allergy_drugs
        .filter((item) => item.value !== "")
        .map((item) => item.value),
      chronic_medication_drugs: data.chronic_medication_drugs
        .filter((item) => item.value !== "")
        .map((item) => item.value),
    });
    if (response.status === 200) {
      getPatientCallback();
    }
  };

  const columns = useMemo(() => [
    { Header: "Datum", accessor: "date" },
    { Header: "Váha (kg)", accessor: "weight" },
    { Header: "Výška (cm)", accessor: "height" },
  ]);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      allergy_drugs: [{ label: "Vyhledejte...", value: "" }],
      chronic_medication_drugs: [{ label: "Vyhledejte...", value: "" }],
      body_constitution: { label: "Vyhledejte...", value: "" },
    },
  });

  const setFormValues = async (data) => {
    methods.setValue("weight", data.weight);
    methods.setValue("height", data.height);
    methods.setValue("correction_factor", data.correction_factor);
    methods.setValue("allergy_note", data.allergy_note);
    methods.setValue("chronic_medication_note", data.chronic_medication_note);
    methods.setValue("body_constitution_note", data.body_constitution_note);

    if (data.body_constitution) {
      methods.setValue("body_constitution", {
        value: data.body_constitution?.id,
        label: data.body_constitution?.name,
      });
    } else {
      methods.setValue("body_constitution", {
        label: "Vyhledejte...",
        value: "",
      });
    }

    methods.setValue(
      "allergy_drugs",
      helpFunc.parseDataForDrugsSelectNoSync(data.allergy_drugs)
    );
    methods.setValue(
      "chronic_medication_drugs",
      helpFunc.parseDataForDrugsSelectNoSync(data.chronic_medication_drugs)
    );
  };

  return { weightHistory, columns, methods, handleSavePatient };
};

const schema = yup.object().shape({
  weight: yup.number(validation.number).required(validation.required),
  height: yup.number(validation.number).required(validation.required),
});
