import React, { useContext, Fragment } from "react";
import { TableContext } from "./WeightHistoryTable.jsx";

export const WeightHistoryTableBody = ({ id, ...props }) => {
  const { getTableBodyProps, rows, prepareRow, visibleColumns } =
    useContext(TableContext);

  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <Fragment key={row.index}>
            <tr {...row.getRowProps()} key={row.id}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          </Fragment>
        );
      })}
    </tbody>
  );
};
