import React, { useContext, Fragment } from "react";
import { TableContext } from "./Table";
import {
  FloppyDisk,
  PatientHospitalPastTable,
  PatientInfoTable,
  PatientSearchTable,
  Pencil,
} from "../../components";
import { useHeightAndWeightEdit } from "../../hooks";

export const TableBody = ({ id, nestedTable, status = "", ...props }) => {
  const { getTableBodyProps, rows, prepareRow, visibleColumns } =
    useContext(TableContext);

  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <Fragment key={row.index}>
            <tr>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.column.id === "height" ? (
                      <EditableTableColumn
                        type="editHeight"
                        cell={cell}
                        patient={rows[0].original}
                        getPatientData={props.getPatientData}
                      />
                    ) : cell.column.id === "weight" ? (
                      <EditableTableColumn
                        type="editWeight"
                        cell={cell}
                        patient={rows[0].original}
                        getPatientData={props.getPatientData}
                      />
                    ) : (
                      cell.render("Cell")
                    )}
                  </td>
                );
              })}
            </tr>

            {row.isExpanded ? (
              <tr>
                <td colSpan={visibleColumns.length}>
                  {status === "past" ? (
                    <PatientHospitalPastTable row={row} />
                  ) : status === "all" ? (
                    <PatientSearchTable row={row} />
                  ) : (
                    <PatientInfoTable
                      row={row}
                      careType={row.original.current_care.care_type}
                    />
                  )}
                </td>
              </tr>
            ) : null}
          </Fragment>
        );
      })}
    </tbody>
  );
};

const EditableTableColumn = ({ type, cell, patient, getPatientData }) => {
  const heightAndWeight = useHeightAndWeightEdit({ patient });

  const handleUpdateHeightAndWeight = async () => {
    const response = await heightAndWeight.handleUpdate(type);
    getPatientData(response, response.current_care);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        width: "100%",
      }}
    >
      {!heightAndWeight.state[type] ? (
        cell.render("Cell")
      ) : (
        <input
          className="inp-text"
          name={cell.column.id}
          value={heightAndWeight.state[cell.column.id]}
          style={{ width: "80%" }}
          onChange={(event) => heightAndWeight.handleChange(event)}
        />
      )}
      {!heightAndWeight.state[type] ? (
        <Pencil
          onClick={() => heightAndWeight.handleShow(type)}
          style={{ width: 30, cursor: "pointer" }}
        />
      ) : (
        <FloppyDisk
          onClick={handleUpdateHeightAndWeight}
          style={{ width: 30, height: 30, cursor: "pointer" }}
        />
      )}
    </div>
  );
};
