import React from "react";
import { FormProvider } from "react-hook-form";
import {
  PharmacologicalPlanLogic,
  Table,
  Form,
  TextAreaInp,
  TextAreaFormInp,
  CheckinPharmaPlan,
  ControlSelect,
  DatePicker,
  PopUp,
  ColorStripe,
  PageNavigation,
  CareProceduresPharmaPlan,
} from "../../components";
import { FaRegCaretSquareDown, FaRegCaretSquareUp } from "react-icons/fa";
import { helpFunc } from "../../helpers/helpFunction";
import { PatientConditionChange } from "./PatientConditionChange/PatientConditionChange";
import { PredefinedTextModal } from "../PredefinedText/PredefinedTextModal";

export const PharmacologicalPlan = ({
  patient,
  plan = null,
  careID,
  careIsLocked = false,
  checkin,
  care = {},
  backUrl,
  ...props
}) => {
  const {
    navigation,
    error,
    table,
    tags,
    options,
    formActivation,
    popUp,
    textContainers,
    procedures,
    pharmaPlan,
    recommendationDischarge,
    recommendation,
    planVerification,
    planEvaluation,
    noteState,
    currentDate,
    patientRiskLevel,
    columns,
    planMethods,
    planVerificationMethods,
    recomendationMethods,
    recommendationDischargeMethods,
    pharmacologicalPlanEvaluationMethods,
    predefinedTtextModal,
    setPredefinedTextModal,
    savePlan,
    saveRecommendation,
    savePlanVerification,
    saveRecommendationDischarge,
    savePlanEvaluation,
    togglePopUp,
    showPopup,
    navigationClick,
    activateField,
    toggleMoreInfo,
    predefinedTextSetter,
    getPatientData,
  } = PharmacologicalPlanLogic(
    plan,
    checkin,
    patient,
    care,
    careIsLocked,
    backUrl
  );

  const careType = care.care_type;
  // const hideEvaluationForm = textContainers.list.some(
  //   (item) => item?.props?.type === "evaluation"
  // );

  return (
    <>
      <div className="pharma-plan-goback-btn">
        <button type="button" className="button" onClick={showPopup}>
          Zpět
        </button>
      </div>
      <div className="patient-profile pharmacological-plan">
        {/* Simple table */}
        {!popUp.hide && popUp.popUp}
        {error.error && <PopUp message={error.message} toggle={togglePopUp} />}
        <div className="myContent">
          <div className="grid-row">
            <div className="grid-column">
              {table.loading ? (
                <h1>Loading table...</h1>
              ) : table.data ? (
                <>
                  <Table
                    data={table.data}
                    columns={columns}
                    pharmaPlan
                    careType={careType}
                    getPatientData={getPatientData}
                  />
                  <ColorStripe className={`color-stripe-${patientRiskLevel}`} />
                </>
              ) : (
                <h2>Nebyly nalezeny informace o pacientovi</h2>
              )}
            </div>
          </div>
          <div className="grid-row">
            <div className="grid-column flex-2">
              <FormProvider {...planMethods}>
                <Form className="myForm">
                  <div className="form-row">
                    <div className="form-column tags-date">
                      {/* only when tags exists */}
                      {!tags.listLoading && tags.tagList && (
                        <div className="tag-row">{tags.tagList}</div>
                      )}
                      <div className="tag-inp">
                        {!tags.loading && (
                          <ControlSelect
                            placeholder="Přidat štítek"
                            options={tags.options}
                            name="tags"
                            isMulti={true}
                            containerClass=""
                            isDisabled={careIsLocked}
                          />
                        )}
                        {!careIsLocked && (
                          <button
                            type="button"
                            className="button"
                            onClick={planMethods.handleSubmit(savePlan)}
                          >
                            Uložit
                          </button>
                        )}
                      </div>
                    </div>
                    <div
                      className="form-column tags-date"
                      style={{ paddingRight: 0 }}
                    >
                      <DatePicker
                        defaultValue={currentDate}
                        labelClass="strong color-b"
                        label="Datum upozornění"
                        name="notification_date"
                        isDisabled={careIsLocked}
                      />
                    </div>
                  </div>
                  {pharmaPlan.submit ||
                    (pharmaPlan.saved && (
                      <div className="form-row">
                        {pharmaPlan.submit && (
                          <span className="strong">Data se ukládají...</span>
                        )}
                        {pharmaPlan.saved && (
                          <span className="strong">Data jsou uložena</span>
                        )}
                      </div>
                    ))}
                </Form>
              </FormProvider>
              {careType === "hospitalization" && (
                <div className="form-row m-bot-0">
                  {/* IN PAGE NAVIGATION */}

                  <PageNavigation className="sub-page-navigation present">
                    {navigation.pharmaPlanVerification && (
                      <div>
                        <a
                          className={
                            navigation.pharmaPlanVerificationActive
                              ? "active"
                              : null
                          }
                          id="pharmaPlanVerification"
                          onClick={navigationClick}
                        >
                          Aktualizace plánu
                        </a>
                      </div>
                    )}
                    {navigation.pharmaPlan && (
                      <div>
                        <a
                          className={
                            navigation.pharmaPlanActive ? "active" : null
                          }
                          id="pharmaPlan"
                          onClick={navigationClick}
                        >
                          Plán farmakoterapie
                        </a>
                      </div>
                    )}

                    <div>
                      <a
                        className={
                          navigation.pharmaPlanReocommendation ? "active" : null
                        }
                        id="pharmaPlanReocommendation"
                        onClick={navigationClick}
                      >
                        Farmakoterapeutické doporučení
                      </a>
                    </div>
                    <div
                      className={
                        recommendationDischarge?.data?.length > 0
                          ? "isDisable"
                          : null
                      }
                    >
                      <a
                        className={
                          navigation.pharmaPlanReocommendationPrint
                            ? "active"
                            : null
                        }
                        id="pharmaPlanReocommendationPrint"
                        onClick={navigationClick}
                      >
                        Farm. dop. do propouštěcí zprávy
                      </a>
                    </div>
                  </PageNavigation>
                </div>
              )}
              {/* Text inputs according to active tab in navigation with text formating */}
              {/* CHANGE IT FOR PLAN VERIFICATION WHEN PLAN IS NOT NULL */}
              <div className="pharmaplan-predefined-btn">
                <button
                  className="button"
                  onClick={() => setPredefinedTextModal(true)}
                >
                  Předdefinované texty
                </button>
              </div>
              {predefinedTtextModal && (
                <PredefinedTextModal
                  close={setPredefinedTextModal}
                  methods={predefinedTextSetter}
                />
              )}
              {navigation.pharmacologicalPlanEvaluation && (
                <FormProvider {...pharmacologicalPlanEvaluationMethods}>
                  <Form className="myForm">
                    <div className="form-row text-inp">
                      <TextAreaFormInp
                        name="text"
                        rows={5}
                        columns={15}
                        maxlenght={5000}
                        containerClass="pharmacological-text-inp"
                        disabled={careIsLocked}
                      />
                    </div>
                    <div className="form-row">
                      <ControlSelect
                        name="evaluation_count"
                        options={options.planEvaluation}
                      />
                    </div>
                    <div className="form-row-button">
                      {!careIsLocked && (
                        <button
                          type="button"
                          className="button"
                          onClick={pharmacologicalPlanEvaluationMethods.handleSubmit(
                            savePlanEvaluation
                          )}
                        >
                          Uložit
                        </button>
                      )}
                    </div>
                    {planEvaluation.submit ||
                      (planEvaluation.saved && (
                        <div className="form-row">
                          {planEvaluation.submit && (
                            <span className="strong">
                              Konziliární zhodnocení se ukládá...
                            </span>
                          )}
                          {planEvaluation.saved && (
                            <span className="strong">
                              Konziliární zhodnocení je uloženo.
                            </span>
                          )}
                        </div>
                      ))}
                  </Form>
                </FormProvider>
              )}
              {navigation.pharmaPlanActive && (
                <FormProvider {...planMethods}>
                  <Form>
                    <div className="form-row text-inp">
                      <TextAreaFormInp
                        name="texts"
                        rows={5}
                        columns={15}
                        maxlenght={5000}
                        containerClass="pharmacological-text-inp"
                        disabled={careIsLocked}
                      />
                    </div>
                    <div className="form-row-button">
                      {!careIsLocked && (
                        <button
                          type="button"
                          className="button"
                          onClick={planMethods.handleSubmit(savePlan)}
                        >
                          Uložit
                        </button>
                      )}
                    </div>
                    <div className="form-row">
                      {pharmaPlan.submit && (
                        <span className="strong">
                          Plán farmakoterapie se ukládá...
                        </span>
                      )}
                      {pharmaPlan.saved && (
                        <span className="strong">
                          Plán farmakoterapie je uložen.
                        </span>
                      )}
                    </div>
                  </Form>
                </FormProvider>
              )}
              {navigation.pharmaPlanVerificationActive && (
                <FormProvider {...planVerificationMethods}>
                  <Form>
                    <div className="form-row text-inp">
                      <TextAreaFormInp
                        name="text"
                        row={5}
                        columns={15}
                        maxlenght={5000}
                        containerClass="pharmacological-text-inp"
                        disabled={careIsLocked}
                      />
                    </div>
                    <div className="form-row-button">
                      {!careIsLocked && (
                        <button
                          type="button"
                          className="button"
                          onClick={planVerificationMethods.handleSubmit(
                            savePlanVerification
                          )}
                        >
                          Uložit
                        </button>
                      )}
                    </div>
                    <div className="form-row">
                      {planVerification.submit && (
                        <span className="strong">
                          Ověření plánu se ukládá...
                        </span>
                      )}
                      {planVerification.saved && (
                        <span className="strong">
                          Ověření plánu je uloženo.
                        </span>
                      )}
                    </div>
                  </Form>
                </FormProvider>
              )}
              {navigation.pharmaPlanReocommendation && (
                <FormProvider {...recomendationMethods}>
                  <Form>
                    <div className="form-row text-inp">
                      <TextAreaFormInp
                        row={5}
                        columns={15}
                        name="text"
                        maxlenght={5000}
                        containerClass="pharmacological-text-inp"
                        disabled={careIsLocked}
                      />
                    </div>
                    <div className="form-row-button">
                      {!careIsLocked && (
                        <button
                          type="button"
                          className="button"
                          onClick={recomendationMethods.handleSubmit(
                            saveRecommendation
                          )}
                        >
                          Uložit
                        </button>
                      )}
                    </div>
                    <div className="form-row">
                      {recommendation.submit && (
                        <span className="strong">
                          Farmakoterapeutické doporučení se ukládají...
                        </span>
                      )}
                      {recommendation.saved && (
                        <span className="strong">
                          Farmakoterapeutické doporučení je uloženo.
                        </span>
                      )}
                    </div>
                  </Form>
                </FormProvider>
              )}
              {navigation.pharmaPlanReocommendationPrint && (
                <FormProvider {...recommendationDischargeMethods}>
                  <Form>
                    <div className="form-row text-inp">
                      <TextAreaFormInp
                        row={5}
                        columns={15}
                        name="text"
                        maxlenght={5000}
                        containerClass="pharmacological-text-inp"
                        disabled={careIsLocked}
                      />
                    </div>
                    <div className="form-row-button">
                      {!careIsLocked && (
                        <button
                          type="button"
                          className="button"
                          onClick={recommendationDischargeMethods.handleSubmit(
                            saveRecommendationDischarge
                          )}
                        >
                          Uložit
                        </button>
                      )}
                    </div>
                    <div className="form-row">
                      {recommendationDischarge.submit && (
                        <span className="strong">
                          Farmakoterapeutické doporučení do propouštěcí zprávy
                          se ukládá...
                        </span>
                      )}
                      {recommendationDischarge.saved && (
                        <span className="strong">
                          Farmakoterapeutické doporučení do propouštěcí zprávy
                          je uloženo.
                        </span>
                      )}
                    </div>
                  </Form>
                </FormProvider>
              )}
              {plan === null &&
              !recommendationDischarge.data &&
              !recommendation.list &&
              !planVerification.list ? (
                <div className="grid-row text-container">
                  {/* If there is not comments saved now */}
                  <span>Nejsou uloženy žádné záznamy</span>
                </div>
              ) : (
                <div className="grid-row">
                  <div className="grid-column">
                    {/* Component pro farmakologicke doporuceni a doporuceni do propousteci zpravy, copy to clipboard, show more, edit */}
                    {!textContainers.loading && textContainers.list}
                  </div>
                </div>
              )}
            </div>
            {/* RIGHT COLUMN */}
            <div className="grid-column flex-1">
              {/* checkin component showing patient checkin data */}
              <div className="grid-row">
                {!table.loading && (
                  <CheckinPharmaPlan
                    checkin={checkin}
                    careIsLocked={careIsLocked}
                  />
                )}
              </div>
              {careType === "hospitalization" && (
                <div className="grid-row">
                  {checkin?.risk_level === "1" && (
                    <PatientConditionChange
                      planID={plan?.id}
                      careIsLocked={careIsLocked}
                      conditionChange={plan?.patient_condition_change}
                    />
                  )}
                </div>
              )}
              <div className="grid-row">
                {/* Medical procedures table */}
                <CareProceduresPharmaPlan
                  careId={careID}
                  reload={procedures.reload}
                  careType={careType}
                />
              </div>
              {/* Note form */}
              <FormProvider {...planMethods}>
                <div className="grid-row notes">
                  <span className="strong">
                    {careType === "hospitalization"
                      ? "Poznámka k vizitě"
                      : "Poznámka"}
                  </span>
                  {!careIsLocked && (
                    <>
                      {formActivation.note ? (
                        <button
                          className="button"
                          type="button"
                          id="note"
                          onClick={planMethods.handleSubmit(savePlan)}
                        >
                          Uložit
                        </button>
                      ) : (
                        <button
                          className="button"
                          type="button"
                          id="note"
                          onClick={activateField}
                        >
                          Editovat
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div className="form-row text-inp">
                  <Form>
                    {!noteState.loading && !formActivation.note ? (
                      <div>
                        {formActivation.hideNote ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: helpFunc.cutText(
                                noteState.text || "",
                                500
                              ),
                            }}
                          />
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{ __html: noteState.text }}
                          />
                        )}
                        <div className="text-container-more-info">
                          {!formActivation.note &&
                            (formActivation.hideNote ? (
                              <FaRegCaretSquareDown onClick={toggleMoreInfo} />
                            ) : (
                              <FaRegCaretSquareUp onClick={toggleMoreInfo} />
                            ))}
                        </div>
                      </div>
                    ) : (
                      <TextAreaFormInp
                        name="note"
                        rows={5}
                        columns={15}
                        maxlenght={5000}
                        disabled={!formActivation.note}
                        containerClass="pharmacological-text-inp"
                      />
                      // <TextAreaInp
                      //   rows={10}
                      //   columns={25}
                      //   name="note"
                      //   maxlenght={5000}
                      //   disabled={!formActivation.note}
                      //   containerClass="w-100"
                      //   showMore={true}
                      // />
                    )}
                  </Form>
                </div>
              </FormProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
