import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { patientService } from "../../services/patient.services";
import { TagBlock, TextContainer, PopUpPharmaPlan } from "../../components";
import { helpFunc } from "../../helpers/helpFunction";
import { useAuth } from "../../contexts/AuthContext";

export const PharmacologicalPlanLogic = (
  plan = null,
  checkin = null,
  patient = null,
  care = null,
  careIsLocked = false,
  backUrl
) => {
  const [navigation, setNavigation] = useState({
    pharmaPlanActive: false,
    pharmaPlanVerificationActive: false,
    pharmaPlan: false,
    pharmaPlanReocommendation: false,
    pharmaPlanReocommendationPrint: false,
    pharmaPlanVerification: false,
    pharmacologicalPlanEvaluation: false,
  });
  const [error, setError] = useState({
    error: false,
    popUp: false,
  });
  const [formActivation, setFormActivation] = useState({
    note: false,
    edit: false,
    hideNote: true,
  });
  const [table, setTable] = useState({ loading: true, data: false });
  const [tags, setTags] = useState({
    loading: true,
    listLoading: true,
    options: false,
    tagList: false,
    usedTags: false,
    idList: [],
  });
  const [pharmaPlan, setPharmaPlan] = useState({
    loading: true,
    submit: false,
    saved: false,
    list: false,
    data: false,
    text: false,
  });
  const [noteState, setNoteState] = useState({ text: "", loading: true });
  const [planVerification, setPlanVerification] = useState({
    loading: true,
    submit: false,
    saved: false,
    data: [],
  });
  const [recommendation, setRecommendation] = useState({
    loading: true,
    submit: false,
    saved: false,
    data: [],
  });
  const [recommendationDischarge, setRecommendationDischarge] = useState({
    loading: true,
    data: [],
    submit: false,
    saved: false,
  });
  const [textContainers, setTextcontainers] = useState({
    loading: true,
    list: [],
  });
  const [planEvaluation, setPlanEvaluation] = useState({
    loading: true,
    submit: false,
    saved: false,
    data: [],
  });
  const [popUp, setPopUp] = useState({ hide: true, popUp: null });
  const [predefinedTtextModal, setPredefinedTextModal] = useState(false);
  const [procedures, setProcedures] = useState({ reload: false });
  const history = useHistory();
  const currentDate = helpFunc.getCurrentDate();
  const { checkLicense } = useAuth();

  const options = {
    planEvaluation: [
      { name: "1", label: "Zhodnocení 1x" },
      { name: "2", label: "Zhodnocení 2x" },
      { name: "3", label: "Zhodnocení 3x" },
      { name: "4", label: "Zhodnocení 4x" },
    ],
  };
  //LOADING DATA ON COMPONENT MOUNT
  useEffect(() => {
    // get correct navigation
    getCurrentNavigation(plan);
    //set patient information data for header table
    getPatientData(patient, care);
    //get tags for select
    getTags();

    //Check if patient has pharma plan already
    if (plan) {
      //load data outside main form -> verifications, recommendations, recommendations discharge
      localStorage.setItem("planId", plan.id);
      loadData(plan);
    }
  }, []);

  //get patient risk level for stripe color under the table and checkin heading background
  const getPatientRiskLevel = (checkin = {}) => {
    switch (checkin?.risk_level) {
      case "1":
        return "blue";
      case "2":
        return "orange";
      case "3":
        return "red";
      case "none":
        return "none";
    }
  };
  const patientRiskLevel = getPatientRiskLevel(checkin);

  // Get data from patient and parse to for use in toggeling table
  const getPatientData = async (patient, care) => {
    if (patient) {
      try {
        setTable({ ...table, loading: true });
        let data = await helpFunc.pPatientData(patient);
        data = {
          ...data,
          main_diagnosis: care?.main_diagnosis?.name || "Žádná",
          last_dekurz:
            helpFunc.formatDate(care?.last_dekurz?.made_at) || "Žádné datum",
          doctor: helpFunc.pDoctorName(care?.last_dekurz?.doctor),
          clinic: care?.clinic?.abbreviation || "Chybí data o klinice",
          department:
            patient.current_care_last_dekurz_department_description ||
            "Chybí data o oddělení",
          first_visit: helpFunc.formatDate(patient.current_care.started_at),
          ambulant_doctor: care?.ambulant_doctor
            ? `${care?.ambulant_doctor.f_title} ${care?.ambulant_doctor.name} ${care?.ambulant_doctor.l_title}`
            : "Žádné informace",
          external_doctor: care?.doctor || "Žádné informace",
        };
        setTable({ ...table, loading: false, data: [data] });
      } catch (e) {
        raiseError("Chyba při načítání informací o pacientovi.");
      }
    } else {
      setTable({ ...table, loading: false, data: null });
    }
  };

  //Get tags form db && parse for use in select
  const getTags = async () => {
    try {
      setTags({ ...tags, loading: true });
      let data = await patientService.getTags();
      data = await helpFunc.pDataForSelect(data.results, "tags");
      setTags((prevState) => ({ ...tags, options: data, loading: false }));
      setPharmaPlan({ ...pharmaPlan, loading: false });
      setPlanVerification({ ...planVerification, loading: false });
    } catch (error) {
      raiseError(error.message);
    }
  };

  //listing all tags into tag blocks with delete funcionality
  const listTags = async (tags) => {
    return tags.map((tag) => {
      return (
        <TagBlock
          key={tag.id}
          title={tag.name}
          id={tag.id}
          delTag={deleteTag}
        />
      );
    });
  };

  //delete tags
  const deleteTag = (id) => {
    let updateList;
    setTags((ref) => {
      updateList = ref.idList.filter((tag) => tag !== id);
      return { ...ref, idList: updateList };
    });
  };

  const loadData = async () => {
    try {
      let response = await patientService.getPharmaPlan(plan.id);
      await setFormValues(response);
      setPlanVerification({
        ...planVerification,
        loading: false,
        data: response.verifications,
      });
      setRecommendation({
        ...recommendation,
        loading: false,
        data: response.recommendations,
      });
      setRecommendationDischarge({
        ...recommendationDischarge,
        loading: false,
        data: response.discharge_recommendations,
      });
      setPlanEvaluation({
        ...planEvaluation,
        loading: false,
        data: response.evaluations,
      });
    } catch (e) {
      console.error(e);
      raiseError("Chyba při načítání farmakologického plánu");
    }
  };

  //Order all text coniners according to date
  useEffect(() => {
    if (
      !(
        pharmaPlan.loading ||
        recommendation.loading ||
        recommendationDischarge.loading ||
        planVerification.loading ||
        planEvaluation.loading
      )
    ) {
      pTextContainerData();
    }
  }, [
    pharmaPlan.loading,
    recommendation.loading,
    recommendationDischarge.loading,
    planVerification.loading,
    planEvaluation.loading,
  ]);

  //parse and order all data for text container
  const pTextContainerData = () => {
    setTextcontainers({ ...textContainers, loading: true });
    try {
      //adding type to data
      const planData = plan?.texts.length
        ? [{ ...plan.texts[0], type: "plan" }]
        : [];
      const planVerificationData = planVerification.data
        ? planVerification.data.map((item) => {
            return { ...item, type: "verification" };
          })
        : [];
      const planRecommendataionData = recommendation.data
        ? recommendation.data.map((item) => {
            return { ...item, type: "recommendation" };
          })
        : [];
      const planEvaluationData = planEvaluation.data
        ? planEvaluation.data.map((item) => {
            return { ...item, type: "evaluation" };
          })
        : [];
      const planDischargeData = recommendationDischarge.data[0]
        ? [
            {
              ...recommendationDischarge.data[0],
              type: "recommendationDischarge",
            },
          ]
        : [];

      //merge data together
      const mergedData = [
        ...planData,
        ...planVerificationData,
        ...planRecommendataionData,
        ...planDischargeData,
        ...planEvaluationData,
      ];

      //sorting data form newest to oldest
      const sortData = mergedData.sort(
        (a, b) => Date.parse(b?.created_at) - Date.parse(a?.created_at)
      );
      //listing data text containers
      const list = helpFunc.pTextContainer(
        sortData,
        "text",
        careIsLocked,
        onDeletePlan,
        handleReloadProcedures,
        checkLicense
      );
      setTextcontainers({ ...textContainers, loading: false, list: list });
    } catch {
      raiseError("Chyba při načítání záznamů.");
    }
  };

  //NAVIGATION
  //Show current navigation if there is plan or not
  const getCurrentNavigation = (plan) => {
    if (care.care_type === "hospitalization") {
      if (plan?.texts?.length) {
        setNavigation({
          ...navigation,
          pharmaPlanVerification: true,
          pharmaPlanVerificationActive: true,
        });
      } else {
        setNavigation({
          ...navigation,
          pharmaPlan: true,
          pharmaPlanActive: true,
        });
      }
    } else {
      setNavigation({
        ...navigation,
        pharmacologicalPlanEvaluation: true,
      });
    }
  };

  //Change navigation on click
  const navigationClick = (value) => {
    switch (value.target.id) {
      case "pharmaPlan":
        setNavigation({
          ...navigation,
          pharmaPlanVerificationActive: false,
          pharmaPlanActive: true,
          pharmaPlanReocommendation: false,
          pharmaPlanReocommendationPrint: false,
        });
        break;
      case "pharmaPlanVerification":
        setNavigation({
          ...navigation,
          pharmaPlanVerificationActive: true,
          pharmaPlanActive: false,
          pharmaPlanReocommendation: false,
          pharmaPlanReocommendationPrint: false,
        });
        break;
      case "pharmaPlanReocommendation":
        setNavigation({
          ...navigation,
          pharmaPlanVerificationActive: false,
          pharmaPlanActive: false,
          pharmaPlanReocommendation: true,
          pharmaPlanReocommendationPrint: false,
        });
        break;
      case "pharmaPlanReocommendationPrint":
        setNavigation({
          ...navigation,
          pharmaPlanVerificationActive: false,
          pharmaPlanActive: false,
          pharmaPlanReocommendation: false,
          pharmaPlanReocommendationPrint: true,
        });
        break;
      default:
        setNavigation({
          ...navigation,
          pharmaPlanVerificationActive: false,
          pharmaPlanActive: true,
          pharmaPlanReocommendation: false,
          pharmaPlanReocommendationPrint: false,
        });
        break;
    }
  };

  //FORM SECTION
  //Form methods for pharmacological plan form
  //Form logic & default values
  const planMethods = useForm({
    defaultValues: {
      texts: "",
      note: "",
      tags: [],
      notification_date: "",
    },
  });

  //Verifications methods
  const planVerificationMethods = useForm({
    defaultValues: {
      text: "",
    },
  });

  //RECOMMENTDATION SECTION
  const recomendationMethods = useForm({
    defaultValues: {
      text: "",
    },
  });

  //RECOMMENTDATION SECTION
  //Show current navigation if there is plan or not
  const recommendationDischargeMethods = useForm({
    defaultValues: {
      text: "",
    },
  });

  const pharmacologicalPlanEvaluationMethods = useForm({
    defaultValues: {
      text: "",
      evaluation_count: { name: "1", label: "Zhodnocení 1x" },
    },
  });

  //parsing defalut values for form
  const setFormValues = async (plan) => {
    //list tags for user
    try {
      // list saved tags
      const tagList = await listTags(plan.tags);
      setTags((prevState) => ({
        ...prevState,
        tagList: tagList,
        listLoading: false,
        idList: plan.tags.map((tag) => tag.id),
      }));
    } catch {
      throw Error("Chyba při načítání štítků");
    }

    const notification_date =
      plan.notification_date !== null ? new Date(plan.notification_date) : "";
    planMethods.setValue("notification_date", notification_date);
    const list = (
      <TextContainer rawData={plan} name="text" type="plan" id={plan.id} />
    );
    planMethods.setValue("note", plan.note);
    planMethods.setValue("texts", plan.text);
    setNoteState({ text: plan.note, loading: false });
    setPharmaPlan({ ...pharmaPlan, loading: false, list: list });
  };

  //Activate form field on user click
  const activateField = (value) => {
    let id = value.target.id;
    if (plan !== null) planMethods.setValue("note", noteState.text);
    switch (id) {
      case "note":
        setFormActivation({ ...formActivation, note: true });
    }
  };

  //Show more info on click of the button
  const toggleMoreInfo = () => {
    setFormActivation({
      ...formActivation,
      hideNote: !formActivation.hideNote,
    });
  };

  //SAVIG DATA
  // Saving or updating data in pharma plan
  const savePlan = async (data = null) => {
    //in case data did not come pull it from form state
    if (!data) {
      data = planMethods.getValues();
    }

    try {
      if (plan) {
        await patchPharmaPlan(data);
      }
    } catch (err) {
      raiseError(err.message);
    }

    handleSaveAndGoBack();
  };

  //patch pharma plan
  const patchPharmaPlan = async (data = null) => {
    //setting state to submit and saving stage

    setPharmaPlan({ ...pharmaPlan, submit: true, saved: false });
    //load data from form if there no data in fucntion parameter
    if (!data) {
      data = planMethods.getValues();
    }
    let allTags;

    // if (data.texts?.includes("<p><br></p>")) {
    //   delete data.texts;
    // }

    try {
      let pTags = await helpFunc.createAndParseTags(
        helpFunc.objToArrApi(data.tags)
      );
      allTags = pTags.concat(tags.idList);
    } catch (error) {
      setPharmaPlan({ ...pharmaPlan, submit: false, saved: false });
      raiseError(error.message);
      return Promise.reject(error);
    }
    //checking if there is field texts in form if so need to save to separate entity
    if (data.texts?.length) {
      await savePharmaPlanText({
        text: data.texts,
        pharmacological_plan: plan.id,
      });
      clearInput("texts", "", planMethods);
      setPharmaPlan((prevState) => ({ ...prevState, text: true }));
    }

    const date =
      data.notification_date !== null
        ? helpFunc.parseDate(data.notification_date)
        : null;

    try {
      data = { ...data, notification_date: date, tags: allTags };
      let response = await patientService.patchPharmaPlan(data, plan.id);
      let newPlan = await patientService.getPharmaPlan(plan.id);
      clearInput("tags", [], planMethods);
      setFormActivation({ ...formActivation, note: false });
      setNoteState({ text: response.note });
      planMethods.setValue("note", response.note);
      const tagList = await listTags(newPlan.tags);
      setTags({
        ...tags,
        tagList: tagList,
        listLoading: false,
        idList: plan.tags.map((tag) => tag.id),
      });
      setPharmaPlan({
        ...pharmaPlan,
        submit: false,
        saved: true,
        text: data.texts,
      });
      timer(3000, setPharmaPlan);
      loadData();
    } catch (e) {
      setPharmaPlan({ ...pharmaPlan, submit: false, saved: false });
      raiseError(e);
    }
  };

  //Saving texts for pharmacological plan
  const savePharmaPlanText = async (data = null) => {
    try {
      if (!data || whitespaceValidation(data)) {
        throw Error("U farmakologického plánu musí být uveden text");
      }
      //setting states for procedures to false stage
      setProcedures({ reload: false });
      //trying to save data
      let response = await patientService.postPlanTexts(data);
      //adding data type for parsing posted data to comment like container
      response = { ...response, type: "plan" };
      //parsing data to text containers
      addToTextContainers(response);
      //cleanning and text area field input
      clearInput("texts", "", planMethods);
      //changing navigation since from pharma plan to pharmaplan verification
      setNavigation((prev) => {
        return {
          ...prev,
          pharmaPlanVerification: true,
          pharmaPlanVerificationActive: true,
          pharmaPlan: false,
          pharmaPlanActive: false,
        };
      });
      //setting state for procedures to load fresh data
      setProcedures({ reload: true });
    } catch (error) {
      setPharmaPlan({ ...pharmaPlan, submit: false, saved: false });
      raiseError(error);
    }
  };

  //posting verification data to db
  const savePlanVerification = async (data = null) => {
    try {
      if (plan) {
        await patchPharmaPlan();
      }
    } catch (err) {
      raiseError(err.message);
    }

    if (!data) {
      data = planVerificationMethods.getValues();
    }
    try {
      //saving verification to db
      setPlanVerification({ ...planVerification, submit: true, saved: false });
      //setting state for procedures table to not reload
      setProcedures({ reload: false });
      if (!data.text || whitespaceValidation(data.text)) {
        setPlanVerification({ ...planVerification, submit: false });
        throw Error("U aktualizace Plánu farmakoterapie musí být uveden text.");
      }

      data = { ...data, pharmacological_plan: plan.id };
      let response = await patientService.postPlanVerifications(data);
      response = { ...response, type: "verification" };
      addToTextContainers(response);
      setPlanVerification({ ...planVerification, submit: false, saved: true });
      //setting state for procedures table to load fresh data
      setProcedures({ reload: true });
      clearInput("text", "", planVerificationMethods);
      timer(3000, setPlanVerification);
    } catch (error) {
      setPlanVerification({ ...planVerification, submit: false, saved: false });
      raiseError(error.message);
    }

    handleSaveAndGoBack();
  };

  //Saving recommendatiaons
  const saveRecommendation = async (data = null) => {
    try {
      if (plan) {
        await patchPharmaPlan();
      }
    } catch (err) {
      raiseError(err.message);
    }

    if (!data) {
      data = recomendationMethods.getValues();
    }
    try {
      setRecommendation({ ...recommendation, submit: true, saved: false });
      if (!data.text || whitespaceValidation(data.text)) {
        setRecommendation({ ...recommendation, submit: false });
        throw Error("U Farmakoterapeutického doporučení musí být uveden text.");
      }

      data = { ...data, pharmacological_plan: plan.id };
      let response = await patientService.postRecommendation(data);
      response = { ...response, type: "recommendation" };
      addToTextContainers(response);
      setRecommendation({ ...recommendation, submit: false, saved: true });
      clearInput("text", "", recomendationMethods);
      timer(3000, setRecommendation);
    } catch (error) {
      setRecommendation({ ...recommendation, submit: false, saved: false });
      raiseError(error.message);
    }

    handleSaveAndGoBack();
  };

  //Saving recommendation for discharge
  const saveRecommendationDischarge = async (data) => {
    try {
      if (plan) {
        await patchPharmaPlan();
      }
    } catch (err) {
      raiseError(err.message);
    }

    if (!data) {
      data = recommendationDischargeMethods.getValues();
    }
    try {
      setRecommendationDischarge({
        ...recommendationDischarge,
        submit: true,
        saved: false,
      });

      if (!data.text || whitespaceValidation(data.text)) {
        setRecommendationDischarge({
          ...recommendationDischarge,
          submit: true,
          saved: false,
        });
        throw Error(
          "U Farmakoterapeutického doporučení do tiskové zprávy musí být uveden text."
        );
      }

      data = { ...data, pharmacological_plan: plan.id };
      let response = await patientService.postRecommendationsDischarge(data);
      response = { ...response, type: "recommendationDischarge" };
      addToTextContainers(response);
      setRecommendationDischarge({
        ...recommendationDischarge,
        submit: false,
        saved: true,
      });
      clearInput("text", "", recommendationDischargeMethods);
      timer(3000, setRecommendationDischarge);
    } catch (error) {
      raiseError(error.message);
    }

    handleSaveAndGoBack();
  };

  const savePlanEvaluation = async (data) => {
    data = {
      ...data,
      evaluation_count: data.evaluation_count.name,
      pharmacological_plan: plan.id,
    };

    try {
      const procedures = await patientService.getProcedures(care.id);
      let alert = true;
      if (
        procedures.patient_remaining_procedure_05752_count <
        data.evaluation_count
      ) {
        alert = confirm(
          "U pacienta došlo k překročení maximálního počtu výkonů na pojišťovnu. Na pojišťovnu bude odeslán nižší, nebo nulový počet výkonů."
        );
      }
      if (alert) {
        setProcedures({ reload: false });
        setPlanEvaluation({ ...planEvaluation, submit: true, saved: false });
        let response = await patientService.postPlanEvaluation(data);
        if (response.status === 201) {
          response.data = { ...response.data, type: "evaluation" };
          addToTextContainers(response.data);
          setPlanEvaluation({ ...planEvaluation, submit: false, saved: true });
          setProcedures({ reload: true });
          clearInput("text", "", pharmacologicalPlanEvaluationMethods);
          timer(3000, setPlanEvaluation);
        }
      }
    } catch (error) {
      raiseError(error.message);
    }
  };

  //check for empty whitespace string
  const whitespaceValidation = (str) => {
    str = helpFunc.removeHTMLTags(str);
    return str.trim().length === 0;
  };

  const handleReloadProcedures = (value) => {
    setProcedures({ reload: value });
  };
  //Adding new data to list of containers below the form
  const addToTextContainers = (data) => {
    const container = helpFunc.pTextContainer(
      [data],
      "text",
      false,
      onDeletePlan,
      handleReloadProcedures,
      checkLicense
    );
    let list = textContainers.list;
    list.unshift(container[0]);
    setTextcontainers({ ...textContainers, loading: false, list: list });
  };

  //setting time that after some while text announcing that data are saved disapears
  const timer = (time, dispatch) => {
    setTimeout(() => {
      dispatch((prevState) => ({ ...prevState, saved: false }));
    }, time);
  };

  //clearing the input when data are saved
  const clearInput = async (name, val, methods) => {
    methods.setValue(name, val);
  };

  //Handle reload or goback
  const handleSaveAndGoBack = () => {
    let popUp;
    setPopUp((ref) => {
      popUp = ref.hide;
      return ref;
    });

    if (backUrl) {
      history.push(backUrl);
      return;
    }

    if (!popUp) {
      if (care.care_type === "external" && care.is_active) {
        history.push(`/patients-external/present`);
        return;
      }
      if (care.is_active) {
        history.push(
          `/patients-hospital/${care?.clinic?.id}/present/${care.care_type}`
        );
      } else {
        history.push(
          `/patients-hospital/${care?.clinic?.id}/past/${care.care_type}`
        );
      }
    }
  };

  //HANDELING COMMENTS ERROR POPUP
  const togglePopUp = () => {
    resetError();
  };

  //raise error with message
  const raiseError = (message, confirm) => {
    setError({ error: true, message: message, confirm });
  };

  //resetError state
  const resetError = () => {
    setError({ error: false, message: "" });
  };

  //TABLE SECTION
  const columns = useMemo(() => [
    {
      Header: "Informace o pacientovi",
      columns: [
        {
          Header: "Příjmení a jméno pacienta",
          accessor: "name",
        },
        {
          Header: "Datum narození",
          accessor: "birth_date",
        },
        {
          Header: "Diagnóza",
          accessor: "main_diagnosis",
        },
        {
          Header: "Hmotnost",
          accessor: "weight",
        },
        {
          Header: "Výška",
          accessor: "height",
        },
        {
          Header: "BMI",
          accessor: "bmi",
        },
        {
          Header: "Rodné číslo",
          accessor: "birth_number",
        },
        {
          Header: "ID pacienta",
          accessor: care.care_type !== "external" ? "external_id" : "id",
        },
        {
          Header: "Dnů hospitalizace (k dnešnímu dni)",
          accessor: "days",
          show: care.care_type === "hospitalization",
        },

        {
          Header:
            care?.care_type === "hospitalization"
              ? "Datum posledního dekurzu"
              : "Datum návštěvy ambulance",
          accessor:
            care?.care_type === "hospitalization"
              ? "last_dekurz"
              : "first_visit",
        },
        {
          Header:
            care?.care_type === "hospitalization"
              ? "Ošetřující lékař"
              : "Jméno lékaře",
          accessor:
            care?.care_type === "external"
              ? "external_doctor"
              : care?.care_type === "ambulation"
              ? "ambulant_doctor"
              : "doctor",
        },
        {
          Header:
            care?.care_type !== "external"
              ? "Klinika"
              : "Zdravotnické zařízení",
          accessor: "clinic",
        },
        {
          Header: "Oddělení",
          accessor: "department",
        },
      ],
    },
  ]);

  //Go back to previous page
  const showPopup = () => {
    setPopUp({ ...popUp, hide: true });
    let popUpBox;
    if (careIsLocked) {
      popUpBox = (
        <PopUpPharmaPlan
          message="Zdravotní péče je uzamčena. Nemůžete provádět žádné změny."
          hide={hidePopUp}
          saveData={savePlan}
          careIsLocked={careIsLocked}
          clinicID={care?.clinic?.id}
          activeCare={care?.is_active}
          careType={table?.data[0]?.current_care?.care_type}
          backUrl={backUrl}
        />
      );
      setPopUp({ ...popUp, hide: false, popUp: popUpBox });
      return 1;
    }
    switch (true) {
      case navigation.pharmaPlan:
        popUpBox = (
          <PopUpPharmaPlan
            message="Pozor, pokud opustíte tuto stránku, změny nebudou uloženy."
            hide={hidePopUp}
            saveData={savePlan}
            clinicID={care?.clinic?.id}
            activeCare={care?.is_active}
            careType={table?.data[0]?.current_care?.care_type}
            backUrl={backUrl}
          />
        );
        break;
      case navigation.pharmaPlanVerification:
        popUpBox = (
          <PopUpPharmaPlan
            message="Pozor, pokud opustíte tuto stránku, změny nebudou uloženy."
            hide={hidePopUp}
            saveData={savePlanVerification}
            clinicID={care?.clinic?.id}
            activeCare={care?.is_active}
            careType={table?.data[0]?.current_care?.care_type}
            backUrl={backUrl}
          />
        );
        break;
      case navigation.pharmaPlanReocommendation:
        popUpBox = (
          <PopUpPharmaPlan
            message="Pozor, pokud opustíte tuto stránku, změny nebudou uloženy."
            hide={hidePopUp}
            saveData={saveRecommendation}
            clinicID={care?.clinic?.id}
            activeCare={care?.is_active}
            careType={table?.data[0]?.current_care?.care_type}
            backUrl={backUrl}
          />
        );
        break;
      case navigation.pharmaPlanReocommendationPrint:
        popUpBox = (
          <PopUpPharmaPlan
            message="Pozor, pokud opustíte tuto stránku, změny nebudou uloženy."
            hide={hidePopUp}
            saveData={saveRecommendationDischarge}
            clinicID={care?.clinic?.id}
            activeCare={care?.is_active}
            careType={table?.data[0]?.current_care?.care_type}
            backUrl={backUrl}
          />
        );
        break;

      case navigation.pharmacologicalPlanEvaluation:
        popUpBox = (
          <PopUpPharmaPlan
            message="Pozor, pokud opustíte tuto stránku, změny nebudou uloženy."
            hide={hidePopUp}
            saveData={savePlan}
            clinicID={care?.clinic?.id}
            activeCare={care?.is_active}
            careType={table?.data[0]?.current_care?.care_type}
            backUrl={backUrl}
          />
        );
        break;
    }
    setPopUp({ hide: false, popUp: popUpBox });
  };

  const hidePopUp = () => {
    setPopUp({ hide: true, popUp: null });
  };

  const onDeletePlan = () => {
    setNavigation({
      ...navigation,
      pharmaPlanVerification: false,
      pharmaPlanVerificationActive: false,
      pharmaPlan: true,
      pharmaPlanActive: true,
    });
  };

  const predefinedTextSetter = () => {
    if (navigation.pharmacologicalPlanEvaluation)
      return pharmacologicalPlanEvaluationMethods;
    if (navigation.pharmaPlanActive) return planMethods;
    if (navigation.pharmaPlanVerificationActive) return planVerificationMethods;
    if (navigation.pharmaPlanReocommendation) return recomendationMethods;
    if (navigation.pharmaPlanReocommendationPrint)
      return recommendationDischargeMethods;
  };

  return {
    navigation,
    error,
    table,
    tags,
    options,
    formActivation,
    popUp,
    textContainers,
    procedures,
    pharmaPlan,
    recommendationDischarge,
    recommendation,
    planVerification,
    planEvaluation,
    noteState,
    currentDate,
    patientRiskLevel,
    columns,
    planMethods,
    planVerificationMethods,
    recomendationMethods,
    recommendationDischargeMethods,
    pharmacologicalPlanEvaluationMethods,
    predefinedTtextModal,
    setPredefinedTextModal,
    savePlan,
    saveRecommendation,
    savePlanVerification,
    saveRecommendationDischarge,
    savePlanEvaluation,
    patchPharmaPlan,
    togglePopUp,
    navigationClick,
    activateField,
    toggleMoreInfo,
    showPopup,
    predefinedTextSetter,
    getPatientData,
  };
};
