import React from "react";
import {
  AsyncSelectComp,
  Form,
  NumInput,
  PatientInfo,
  PermissionDenied,
  TextAreaFormInp,
  TextInput,
} from "../../components";
import { AnamnezaLogic } from "./AnamnezaLogic";
import { WeightHistoryTable } from "./WeightHistoryTable/WeightHistoryTable";
import { WeightHistoryGraph } from "./WeightHistoryGraph";
import { useAuth } from "../../contexts/AuthContext";
import { FormProvider } from "react-hook-form";
import { AnamnezaAsyncSelectArrComp } from "./AnamnezaAsyncSelectArrComp";

export const Anamneza = ({ patient, careID, getPatientCallback, ...props }) => {
  const { weightHistory, columns, methods, handleSavePatient } = AnamnezaLogic(
    patient,
    getPatientCallback
  );
  const { checkLicense } = useAuth();

  if (!checkLicense("height_and_weight")) {
    return <PermissionDenied />;
  }

  return (
    <>
      <div className="patient-profile anamneza">
        <PatientInfo
          patientData={patient}
          careID={careID}
          getPatientCallback={getPatientCallback}
        />
        <FormProvider {...methods}>
          <Form>
            <div className="myContent">
              <div className="grid-row">
                <div className="col-lg-12 col-md-12">
                  <p className="title mb-2">Habitus</p>

                  <div className="form-row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-column">
                        <NumInput
                          label="Celková hmotnosť pacienta *"
                          id="weight"
                          name="weight"
                          error={methods.formState.errors.weight?.message}
                        />
                        <NumInput
                          label="Výška pacienta *"
                          id="height"
                          name="height"
                          error={methods.formState.errors.height?.message}
                        />
                        <NumInput
                          label="Korekčný faktor léčiva"
                          id="correction_factor"
                          name="correction_factor"
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div class="table-container">
                        <table class="anamneza-table">
                          <thead>
                            <tr>
                              <th>ABW</th>
                              <th>IBW</th>
                              <th>BMI</th>
                              <th>BSA</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{patient.abw}</td>
                              <td>{patient.ibw}</td>
                              <td>{patient.bmi}</td>
                              <td>{patient.bsa}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-lg-6 col-md-12">
                      <div className="form-column">
                        <AsyncSelectComp
                          label="Tělesná konstituce"
                          id="body_constitution"
                          name="body_constitution"
                          active={false}
                          endPoint="body-constitutions"
                        />
                        <TextInput
                          label="Poznámka"
                          id="body_constitution_note"
                          name="body_constitution_note"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row-button">
                    <button
                      type="button"
                      className="button"
                      onClick={methods.handleSubmit(handleSavePatient)}
                    >
                      Uložit
                    </button>
                  </div>
                </div>
              </div>
              <div className="grid-row">
                <div className="col-lg-6 col-md-12">
                  <p className="title mb-2">
                    Historické zápisy hmotnosti a výšky
                  </p>
                  <div className="grid-row">
                    <div className="grid-column">
                      <WeightHistoryTable
                        data={weightHistory}
                        columns={columns}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-row mt-5">
                <div className="col-lg-12 col-md-12">
                  <p className="title mb-2">Zmena hmotnosti v čase</p>
                  <div className="grid-row">
                    <div className="grid-column">
                      <WeightHistoryGraph data={weightHistory} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-row mt-5">
                <div className="col-lg-12 col-md-12">
                  <p className="title mb-2">Alergie</p>
                  <div className="grid-row">
                    <div className="grid-column">
                      <TextAreaFormInp id="allergy_note" name="allergy_note" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-row">
                <div className="col-lg-2 col-md-12">Seznam léčiv</div>
                <div className="col-lg-10 col-md-12">
                  <AnamnezaAsyncSelectArrComp
                    name="allergy_drugs"
                    endPoint="drugs"
                  />
                </div>
              </div>
              <div className="grid-row">
                <div className="col-12">
                  <button
                    type="button"
                    className="button"
                    onClick={methods.handleSubmit(handleSavePatient)}
                  >
                    Uložit
                  </button>
                </div>
              </div>
              <div className="grid-row mt-5">
                <div className="col-lg-12 col-md-12">
                  <p className="title mb-2">Chronická medikace</p>
                  <div className="grid-row">
                    <div className="grid-column">
                      <TextAreaFormInp
                        id="chronic_medication_note"
                        name="chronic_medication_note"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-row">
                <div className="col-lg-2 col-md-12">Seznam léčiv</div>
                <div className="col-lg-10 col-md-12">
                  <AnamnezaAsyncSelectArrComp
                    name="chronic_medication_drugs"
                    endPoint="drugs"
                  />
                </div>
              </div>
              <div className="grid-row">
                <div className="col-12">
                  <button
                    type="button"
                    className="button"
                    onClick={methods.handleSubmit(handleSavePatient)}
                  >
                    Uložit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </FormProvider>
      </div>
    </>
  );
};
