import React, { createContext } from "react";
import { useSortBy, useTable } from "react-table";
import { WeightHistoryTableHead } from "./WeightHistoryTableHead";
import { WeightHistoryTableBody } from "./WeightHistoryTableBody";

export const TableContext = createContext({});

export const WeightHistoryTable = ({ columns, data, ...props }) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: "date",
            desc: true,
          },
        ],
      },
    },
    useSortBy
  );
  const { getTableProps } = tableInstance;

  return (
    <TableContext.Provider value={{ ...tableInstance }}>
      <table className="myTable" {...getTableProps()}>
        <WeightHistoryTableHead />
        <WeightHistoryTableBody />
      </table>
    </TableContext.Provider>
  );
};
