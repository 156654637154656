import { useEffect, useState } from "react";
import { patientService } from "../services/patient.services";

export const useHeightAndWeightEdit = (props) => {
  const { patient } = props;

  useEffect(() => {
    setState({ ...state, height: patient.height, weight: patient.weight });
  }, [patient]);

  const [state, setState] = useState({
    editWeight: false,
    editHeight: false,
    height: null,
    weight: null,
  });

  const handleUpdate = async (type) => {
    const response = await patientService.patchPatient(patient.id, {
      height: state.height,
      weight: state.weight,
    });
    if (response.status === 200) {
      setState({ ...state, [type]: !state[type] });
      return response.data;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleShow = (type) => setState({ ...state, [type]: !state[type] });

  return {
    state,
    handleUpdate,
    handleChange,
    handleShow,
  };
};
