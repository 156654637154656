import React from "react";
import { useFormContext, Controller, useFieldArray } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { FaRegMinusSquare, FaRegPlusSquare } from "react-icons/fa";
import { AsyncSelectArrCompLogic } from "../Form/AsyncSelectArrComp/AsyncSelectArrCompLogic";

export const AnamnezaAsyncSelectArrComp = ({
  name,
  endPoint,
  labelClass,
  isDisabled = false,
  displayBtns = true,
  ...props
}) => {
  const methods = useFormContext;
  const { control } = methods;
  const { fields, remove, append } = useFieldArray({ name: name, control });
  const { promiseOptions } = AsyncSelectArrCompLogic(name, endPoint);

  const styles = {
    control: (props) => ({
      ...props,
      borderRadius: 0,
      minHeight: 42,
      width: 500,
    }),
  };

  return fields.map((item, i) => (
    <div className="anamneza-multiple-select" key={item.id}>
      <Controller
        name={`${name}.${i}`}
        control={methods.control}
        render={({ field }) => (
          <AsyncSelect
            isClearable
            {...field}
            styles={styles}
            isDisabled={isDisabled}
            placeholder="Vyhledejte..."
            cacheOptions
            loadOptions={promiseOptions}
            {...props}
          />
        )}
      />
      {/* Add logic to display icons */}
      {displayBtns && (
        <div className="input-icons">
          <a onClick={() => append({ value: "", label: "Vyhledejte..." })}>
            <FaRegPlusSquare id="plus" />
          </a>
          <a
            onClick={() => {
              fields.length <= 1
                ? alert("Nemůžete odstranit všechna léčiva")
                : remove(i);
            }}
          >
            <FaRegMinusSquare id="minus" />
          </a>
        </div>
      )}
    </div>
  ));
};
