import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

export const WeightHistoryGraph = ({ data }) => {
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("sk-SK", options);
  };

  return (
    <div className="weight-history-graph">
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="none" vertical={false} />
          <XAxis
            dataKey="history_datetime"
            tickFormatter={formatDate}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            label={{
              value: "Hmotnosť (kg)",
              angle: -90,
              position: "insideLeft",
              style: { fontSize: 12 },
              dy: 0,
            }}
            tick={{ fontSize: 12 }}
          />
          <Tooltip
            formatter={(value) => `${value} kg`}
            labelFormatter={(label) => `Dátum: ${formatDate(label)}`}
            content={({ payload, label }) =>
              payload && payload.length ? (
                <div
                  style={{
                    backgroundColor: "white",
                    border: "1px solid #ccd2d7",
                    padding: "5px",
                  }}
                >
                  <p>{`Dátum: ${formatDate(label)}`}</p>
                  <p>{`Váha: ${payload[0].value} kg`}</p>
                </div>
              ) : null
            }
          />
          <Bar dataKey="weight" fill="#02385d" barSize={30} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
