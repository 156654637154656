export const PermissionDenied = () => {
  return (
    <div className="patient-profile anamneza">
      <div className="myContent">
        <div className="grid-row">
          <div className="col-lg-6 col-md-12">
            <p className="title mb-2">
              Nemáte dostatečné oprávnění k zobrazení této stránky
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
