import React from "react";
import { PatientInfoLogic } from "./PatientInfoLogic";
import { CareProcedures } from "../CareProcedures/CareProcedures";
import { helpFunc } from "../../helpers/helpFunction";
import { Pencil } from "../Icons/Pencil";
import { FloppyDisk } from "../Icons/FloppyDisk";

export const PatientInfo = ({
  id,
  patientData,
  careData = {},
  displayCareProcedure,
  careID,
  careProcedures = true,
  getPatientCallback,
  ...props
}) => {
  const {
    state,
    error,
    patient,
    care,
    showMoreInfo,
    handleUpdateHeightAndWeight,
    heightAndWeight,
  } = PatientInfoLogic(id, patientData, careID, careData, getPatientCallback);

  return (
    <div className="patient-info">
      {state.loading ? (
        <h1>Loading...</h1>
      ) : (
        <>
          {error && <h1>Chyba načítání pacienta</h1>}
          <ul>
            <li className="header">
              Příjmení a jméno pacienta
              <ul>
                <li>
                  <strong>{patient?.name}</strong>
                </li>
              </ul>
            </li>
            <li>
              Datum narození
              <ul>
                <li>{patient?.birth_date || "Chybí data"}</li>
              </ul>
            </li>
            <li>
              Diagnóza
              <ul>
                <li>{care?.main_diagnosis?.name || "Žádná diagnóza"}</li>
              </ul>
            </li>
            <li>
              Hmotnost
              <ul>
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {!heightAndWeight.state.editWeight ? (
                    `${patient?.weight} kg` || "Žádné informace"
                  ) : (
                    <input
                      className="inp-text"
                      name="weight"
                      value={heightAndWeight.state.weight}
                      style={{ width: "80%" }}
                      onChange={(event) => heightAndWeight.handleChange(event)}
                    />
                  )}
                  {!heightAndWeight.state.editWeight ? (
                    <Pencil
                      onClick={() => heightAndWeight.handleShow("editWeight")}
                      style={{ width: 30, cursor: "pointer" }}
                    />
                  ) : (
                    <FloppyDisk
                      onClick={() => handleUpdateHeightAndWeight("editWeight")}
                      style={{ width: 30, height: 30, cursor: "pointer" }}
                    />
                  )}
                </li>
              </ul>
            </li>
            <li>
              Výška
              <ul>
                <li
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {!heightAndWeight.state.editHeight ? (
                    `${patient?.height} cm` || "Žádné informace"
                  ) : (
                    <input
                      className="inp-text"
                      name="height"
                      value={heightAndWeight.state.height}
                      style={{ width: "80%" }}
                      onChange={(event) => heightAndWeight.handleChange(event)}
                    />
                  )}
                  {!heightAndWeight.state.editHeight ? (
                    <Pencil
                      onClick={() => heightAndWeight.handleShow("editHeight")}
                      style={{ width: 30, cursor: "pointer" }}
                    />
                  ) : (
                    <FloppyDisk
                      onClick={() => handleUpdateHeightAndWeight("editHeight")}
                      style={{ width: 30, height: 30, cursor: "pointer" }}
                    />
                  )}
                </li>
              </ul>
            </li>
            {care?.care_type !== "external" && (
              <li>
                Poznámka
                <ul>
                  <li>{patient?.note || "Žádné poznámky"}</li>
                </ul>
              </li>
            )}
            {state.moreInfo && (
              <>
                <li>
                  BMI
                  <ul>
                    <li>{patient?.bmi || "Žádné informace"}</li>
                  </ul>
                </li>
                <li>
                  Rodné číslo
                  <ul>
                    <li>{patient?.birth_number}</li>
                  </ul>
                </li>
                <li>
                  ID pacienta
                  <ul>
                    <li>{patient?.external_id ?? patient?.id}</li>
                  </ul>
                </li>
                {care?.care_type === "hospitalization" && (
                  <li>
                    Počet dnů hospitalizace k dnešnímu dni
                    <ul>
                      <li>{patient?.current_care_days || "Žádné informace"}</li>
                    </ul>
                  </li>
                )}
                {care?.care_type === "hospitalization" && (
                  <li>
                    Datum posledního napsaného dekurzu
                    <ul>
                      <li>{patient?.last_dekurz || "Žádné informace"}</li>
                    </ul>
                  </li>
                )}
                {care?.care_type !== "hospitalization" && (
                  <li>
                    Datum návštěvy ambulance
                    <ul>
                      <li>
                        {helpFunc.formatDate(care?.started_at) ||
                          "Žádné informace"}
                      </li>
                    </ul>
                  </li>
                )}
                {care?.care_type === "hospitalization" && (
                  <li>
                    Ošetřující lékař
                    <ul>
                      <li>
                        {care?.last_dekurz?.doctor?.f_title || ""}{" "}
                        {care?.last_dekurz?.doctor?.name || "Žádné informace"}{" "}
                        {care?.last_dekurz?.doctor?.l_title || ""}
                      </li>
                    </ul>
                  </li>
                )}
                {care?.care_type !== "hospitalization" && (
                  <li>
                    Jméno lékaře (žadatele)
                    <ul>
                      <li>
                        {care?.ambulant_doctor
                          ? `${care?.ambulant_doctor.f_title || ""} ${
                              care?.ambulant_doctor.name
                            } ${care?.ambulant_doctor.l_title || ""}`
                          : "Žádné informace"}
                      </li>
                    </ul>
                  </li>
                )}
                <li>
                  {care?.care_type !== "hospitalization"
                    ? "Zdravotnické zařízení"
                    : "Klinika"}
                  <ul>
                    <li>{care?.clinic?.description || "Žádné informace"}</li>
                  </ul>
                </li>
                <li>
                  Oddělení
                  <ul>
                    <li>
                      {patient?.current_care_last_dekurz_department_description ??
                        patient?.current_care_department_description}
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
          <div className="form-row-button">
            <button
              className="button patient-info-button"
              type="button"
              onClick={showMoreInfo}
            >
              {state.moreInfo ? "Zabalit zpět" : "Veškeré údaje o pacientovi"}
            </button>
          </div>
          {careProcedures && (
            <CareProcedures
              careId={careID}
              displayCareProcedures={displayCareProcedure}
              careType={care.care_type}
            />
          )}
        </>
      )}
    </div>
  );
};
