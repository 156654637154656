import React, { useContext, useEffect, useState } from "react";
import { userService } from "../services/user.services";

export const AuthContext = React.createContext({
  authToken: localStorage.getItem("authToken"),
  license: [],
  checkLicense: () => {},
  setAuthToken: () => {},
});

export function AuthProvider({ children }) {
  const [license, setLicense] = useState([]);
  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken"));

  useEffect(() => {
    getLicense();
  }, [authToken]);

  const getLicense = async () => {
    const response = await userService.getLicense();
    if (response.data.features.length) {
      setLicense(response.data.features);
    }
  };

  const checkLicense = (type) => license.includes(type);

  return (
    <AuthContext.Provider
      value={{
        authToken,
        license,
        checkLicense,
        setAuthToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
