import React from "react";
import { FormProvider } from "react-hook-form";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCaretSquareDown, FaRegCaretSquareUp } from "react-icons/fa";
import {
  ControlSelect,
  FloppyDisk,
  Form,
  PopUp,
  TextAreaFormInp,
  TextInput,
} from "../../components";
import { TextContainerLogic } from "./TextContainerLogic";
import { helpFunc } from "../../helpers/helpFunction";

export const TextContainer = ({
  rawData = null,
  type = "",
  id = null,
  name = "",
  printBtn = false,
  careIsLocked = false,
  hide = true,
  careType = null,
  ...props
}) => {
  const {
    state,
    data,
    copy,
    formMethods,
    error,
    options,
    clearError,
    createMarkup,
    toggleMoreInfo,
    onClickCopy,
    activateField,
    saveData,
    print,
    createDastaFile,
    deleteData,
  } = TextContainerLogic(
    rawData,
    name,
    id,
    type,
    props.onDeletePlan,
    props.handleReloadProcedures,
    hide
  );
  return (
    <>
      {error.error && <PopUp toggle={clearError} message={error.message} />}
      {!state.delete && (
        <FormProvider {...formMethods}>
          <Form>
            <div
              className="text-container"
              style={data.hasDastaFile ? { backgroundColor: "#A5DEC7" } : {}}
            >
              <div className="text-container-title">
                <span className="strong">{data.type}</span>
                <CopyToClipboard
                  options={{ debug: true, message: "Error" }}
                  text={data.plainText}
                  onCopy={() => onClickCopy(data.plainText)}
                >
                  <FloppyDisk />
                </CopyToClipboard>
                {copy.copy && <span>Text zkopírován.</span>}
              </div>
              <div className="text-container-body">
                <div className="text-container-header">
                  <span className="commentName strong mr-3">{data.author}</span>{" "}
                  |<span className="mr-3 ml-3"> {data.date}</span>
                  {data.clinic ? (
                    <>
                      |
                      <span className="commentName strong ml-3">
                        {careType !== "external"
                          ? data.clinic
                          : "Klinická farmacie"}
                      </span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className="text-container-text mt-3">
                  {state.active ? (
                    <>
                      {type === "evaluation" && (
                        <ControlSelect
                          id="evaluation_count"
                          name="evaluation_count"
                          options={options.planEvaluation}
                          containerClass="pharmacological-text-inp mt-3"
                        />
                      )}
                      <TextAreaFormInp
                        name="text"
                        row={5}
                        columns={15}
                        maxlenght={5000}
                        containerClass="pharmacological-text-inp"
                        disabled={careIsLocked}
                      />
                    </>
                  ) : state.hide ? (
                    <div
                      dangerouslySetInnerHTML={createMarkup(data.textShort)}
                    />
                  ) : (
                    <div dangerouslySetInnerHTML={createMarkup(data.text)} />
                  )}
                </div>
              </div>
              <div className="text-container-btns">
                <div className="text-container-more-info">
                  {!state.active &&
                    (state.hide ? (
                      <FaRegCaretSquareDown onClick={toggleMoreInfo} />
                    ) : (
                      <FaRegCaretSquareUp onClick={toggleMoreInfo} />
                    ))}
                </div>
                <div className="text-container-btns-btns">
                  {state.active ? (
                    <button
                      className="button"
                      onClick={formMethods.handleSubmit(saveData)}
                    >
                      Uložit
                    </button>
                  ) : (
                    !careIsLocked && (
                      <button className="button" onClick={activateField}>
                        Editovat
                      </button>
                    )
                  )}
                  {printBtn && (
                    <button type="button" className="button" onClick={print}>
                      Tisk
                    </button>
                  )}
                  {!careIsLocked && type !== "evaluation" && (
                    <button
                      type="button"
                      className="button btn-light"
                      onClick={deleteData}
                    >
                      Vymazat
                    </button>
                  )}
                  {props.checkLicense("dasta_export") && (
                    <>
                      <button
                        type="button"
                        className="button"
                        onClick={() => createDastaFile(type, id)}
                      >
                        Odeslat záznam
                      </button>
                      {data.dastaFileCreatedAt && (
                        <div className="text-center">
                          Záznam odeslán <br />
                          {helpFunc.formatDateWithTime(data.dastaFileCreatedAt)}
                        </div>
                      )}
                    </>
                  )}

                  {state.submit && <span>Ukládíní...</span>}
                  {state.deleting && <span>Mazání...</span>}
                  {state.saved && <span>Uloženo</span>}
                  {state.delete && <span>Vymazáno</span>}
                </div>
              </div>
            </div>
          </Form>
        </FormProvider>
      )}
    </>
  );
};
